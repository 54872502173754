<script>
export default {
  name: "UiTableColumn",
  inject: ["addColumn"],
  props: {
    label: String,
    width: String,
    prop: String,
    subscript: String,
  },
  data: () => ({
    renderer: null,
  }),
  mounted() {
    this.addColumn({
      label: this.label,
      width: this.width,
      prop: this.prop,
      subscript: this.subscript,
      template: this.$scopedSlots.default ? this.renderCell : null,
    });
  },
  methods: {
    renderCell(data) {
      return this.$scopedSlots.default ? this.$scopedSlots.default(data) : "";
    },
  },
  render(h) {
    return "";
  },
};
</script>

<style scoped lang="scss"></style>
