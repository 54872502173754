<template>
  <div class="ui-table">
    <div class="ui-table__header-wrapper">
      <slot />
      <div class="ui-table__header">
        <div
          v-for="(head, index) in header"
          :key="index"
          class="ui-table__header-cell"
          :style="{ width: head.width ? head.width : '' }"
        >
          {{ head.label }}
        </div>
      </div>
    </div>
    <div class="ui-table__body-wrapper">
      <div class="ui-table__body">
        <div
          v-for="(row, index) in data"
          :key="index"
          class="ui-table__row"
          @click="clickRow(row)"
        >
          <div
            v-for="(head, headindex) in header"
            :key="headindex"
            class="ui-table__body-cell"
            :style="{ width: head.width ? head.width : '' }"
          >
            <vnodes
              v-if="head.template"
              :vnodes="head.template({ row: row })"
            />
            <div v-else>{{ row[head.prop] }}{{ head.subscript }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiTable",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  provide: function () {
    return {
      addColumn: this.addColumn,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    subscript: String,
  },
  data: () => ({
    header: [],
  }),
  methods: {
    addColumn(column) {
      this.header.push(column);
    },
    clickRow(row) {
      this.$emit("click-row", row);
    },
  },
};
</script>

<style scoped lang="scss">
.ui-table {
  &__header {
    display: flex;
    margin-bottom: 5px;

    &-cell {
      font-family: $font-family-bebas;
      font-size: 14px;
      font-weight: 400;
      color: #18181b;
      opacity: 0.5;
      text-align: left;
      margin: 13px 5px 0 17px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
    }
  }

  &__body {
    &-cell {
      font-family: $font-family-rubik;
      font-weight: normal;
      font-size: 16px;
      color: #18181b;
      letter-spacing: 0.03em;
      margin: 13px 5px 13px 17px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
  }

  &__row {
    background: #ffffff;
    box-shadow: 4px 5px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    margin-bottom: 5px;
    position: relative;
  }
}
</style>
