var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ui-table" }, [
    _c(
      "div",
      { staticClass: "ui-table__header-wrapper" },
      [
        _vm._t("default"),
        _c(
          "div",
          { staticClass: "ui-table__header" },
          _vm._l(_vm.header, function (head, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "ui-table__header-cell",
                style: { width: head.width ? head.width : "" },
              },
              [_vm._v(" " + _vm._s(head.label) + " ")]
            )
          }),
          0
        ),
      ],
      2
    ),
    _c("div", { staticClass: "ui-table__body-wrapper" }, [
      _c(
        "div",
        { staticClass: "ui-table__body" },
        _vm._l(_vm.data, function (row, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "ui-table__row",
              on: {
                click: function ($event) {
                  return _vm.clickRow(row)
                },
              },
            },
            _vm._l(_vm.header, function (head, headindex) {
              return _c(
                "div",
                {
                  key: headindex,
                  staticClass: "ui-table__body-cell",
                  style: { width: head.width ? head.width : "" },
                },
                [
                  head.template
                    ? _c("vnodes", {
                        attrs: { vnodes: head.template({ row: row }) },
                      })
                    : _c("div", [
                        _vm._v(_vm._s(row[head.prop]) + _vm._s(head.subscript)),
                      ]),
                ],
                1
              )
            }),
            0
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }